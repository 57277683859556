import style from './style';
import classNames from 'classnames/bind';

let cx = classNames.bind(style);

const CloseIcon = (props) => (
  <svg class={cx('close-icon', { 'close-icon--parallel': props.parallel })} style={{ stroke: props.color || '#00f', strokeWidth: props.strokeWidth || '1px' }} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 15.59 15.82">
    <line class="line-1" x1="0.36" y1="0.35" x2="15.24" y2="15.47" />
    <line class="line-2" x1="0.36" y1="15.47" x2="15.24" y2="0.35" />
  </svg>
)

export default CloseIcon;