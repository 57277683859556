import style from './style';
import classNames from 'classnames/bind';

let cx = classNames.bind(style);

const Logo = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 364.08 137.53" class={cx('logo')} style={{ fill: props.fill }}>
    <g>
      <path d="M330,137.53h-1.5V100.45h35.55V102H330ZM119.43,35.47l-1.06-1.06A19.25,19.25,0,0,0,96.06,3.61l-.68-1.34a20.75,20.75,0,0,1,24.05,33.2Z" />
      <rect  x="158.59" y="60.91" width="1.5" height="63.37"
        transform="translate(-18.8 139.79) rotate(-45)" />
      <path d="M4.94,6.08A16.9,16.9,0,0,1,15.52,2.75a30.19,30.19,0,0,1,6.93.94,2.92,2.92,0,0,0,1.1.16c0,.9.24,3.37.55,5.76A.84.84,0,0,1,23,9.69C22.45,7,20.69,4,15,4,9,4,3.92,7.77,3.92,15.88S9.13,28.65,15.56,28.65a8.37,8.37,0,0,0,8.19-5.6.81.81,0,0,1,1,.15,14.57,14.57,0,0,1-1.88,5.37,8.65,8.65,0,0,0-1.37.39,22.71,22.71,0,0,1-6.19.91,17.17,17.17,0,0,1-9.76-2.63A12.63,12.63,0,0,1,0,16.46,13,13,0,0,1,4.94,6.08Z" />
      <path d="M29.86,19.21c-1,0-1.06.08-1.06,1.05,0,4,2.31,7.33,6.5,7.33a4.22,4.22,0,0,0,3.61-1.92.51.51,0,0,1,.7.47,6.84,6.84,0,0,1-6.15,3.65,7,7,0,0,1-5.68-2.59,8.3,8.3,0,0,1-1.65-5.09c0-4.82,3.18-8.86,7.88-8.86,3.37,0,5.56,2.31,5.56,4.66a2.41,2.41,0,0,1-.15,1c-.12.24-.67.28-2.32.28ZM32.29,18c2.9,0,3.76-.15,4.07-.39.12-.08.24-.24.24-.74,0-1.1-.75-2.47-3-2.47s-4.31,2.11-4.35,3.41c0,.07,0,.19.27.19Z" />
      <path d="M43.33,18c0-1.53,0-1.72-1-2.43l-.36-.23a.56.56,0,0,1,0-.71,20.93,20.93,0,0,0,3.92-1.92.45.45,0,0,1,.43.28c-.08.9-.08,1.49-.08,1.84a.41.41,0,0,0,.43.43,10.76,10.76,0,0,1,5.49-2c3.25,0,4.58,2.23,4.58,4.86v6.74c0,2.82.08,3.21,1.49,3.41l.87.12a.69.69,0,0,1-.08,1c-1.18-.08-2.31-.12-3.69-.12s-2.58,0-3.64.12a.69.69,0,0,1-.08-1l.75-.12c1.45-.24,1.48-.59,1.48-3.41V19c0-2.23-1-3.88-3.68-3.88a4.29,4.29,0,0,0-3.33,1.37,3.31,3.31,0,0,0-.59,2.35v6c0,2.82.08,3.17,1.49,3.41l.71.12a.69.69,0,0,1-.08,1c-1.06-.08-2.2-.12-3.57-.12s-2.58,0-3.68.12a.69.69,0,0,1-.08-1l.82-.12c1.45-.2,1.49-.59,1.49-3.41Z" />
      <path d="M66.33,15.45c-.66,0-.7.11-.7.94v7.32c0,2.28.27,4.35,2.86,4.35a3.22,3.22,0,0,0,1.06-.19c.35-.12.86-.55,1-.55.32,0,.48.43.4.66a5.42,5.42,0,0,1-4.12,1.81c-3.37,0-4.07-1.88-4.07-4.24v-9c0-1.06,0-1.13-.83-1.13H60.81a.65.65,0,0,1-.12-.83,7.75,7.75,0,0,0,2.51-1.21,9.68,9.68,0,0,0,1.64-2.31c.2-.08.67-.08.83.19v1.76c0,.71,0,.75.7.75h4.35a1.26,1.26,0,0,1,0,1.65Z" />
      <path d="M75.62,19.21c-1,0-1.06.08-1.06,1.05,0,4,2.31,7.33,6.5,7.33a4.22,4.22,0,0,0,3.61-1.92.51.51,0,0,1,.7.47,6.84,6.84,0,0,1-6.15,3.65,7,7,0,0,1-5.68-2.59,8.3,8.3,0,0,1-1.65-5.09c0-4.82,3.18-8.86,7.88-8.86,3.37,0,5.56,2.31,5.56,4.66a2.41,2.41,0,0,1-.15,1c-.12.24-.67.28-2.31.28ZM78.05,18c2.9,0,3.76-.15,4.07-.39.12-.08.24-.24.24-.74,0-1.1-.75-2.47-3-2.47S75,16.54,75,17.84c0,.07,0,.19.27.19Z" />
      <path d="M89.09,18c0-1.53,0-1.72-1-2.43l-.36-.23a.56.56,0,0,1,0-.71,29.75,29.75,0,0,0,4-1.92c.2,0,.31.12.31.28v2.27c0,.2,0,.31.12.35,1.37-1.13,2.86-2.35,4.31-2.35a1.63,1.63,0,0,1,1.77,1.57,1.74,1.74,0,0,1-1.69,1.8,1.54,1.54,0,0,1-.86-.27,3.25,3.25,0,0,0-1.73-.63,1.76,1.76,0,0,0-1.41.78,6,6,0,0,0-.51,3v5.33c0,2.82.08,3.29,1.57,3.41l1.41.12a.68.68,0,0,1-.08,1c-1.84-.08-3-.12-4.35-.12s-2.58,0-3.6.12a.69.69,0,0,1-.08-1l.75-.12c1.45-.24,1.48-.59,1.48-3.41Z" />
      <path d="M79.27,49c-.66,0-.66.08-.66,1.14v8.27c0,2.82.08,3.29,1.49,3.41l1.41.12a.69.69,0,0,1-.08,1c-1.76-.08-2.9-.12-4.27-.12s-2.59,0-3.76.12a.68.68,0,0,1-.08-1l.9-.12c1.45-.2,1.49-.59,1.49-3.41V50.18c0-1.06,0-1.14-.79-1.14H73.55a.54.54,0,0,1-.11-.74l1.68-.82a.9.9,0,0,0,.59-.79,17.53,17.53,0,0,1,1.21-6.89,7.71,7.71,0,0,1,7.06-5c2.07,0,3.84.94,3.84,2.35a1.5,1.5,0,0,1-1.49,1.41,1.1,1.1,0,0,1-.94-.47,4.39,4.39,0,0,0-3.14-1.72,3,3,0,0,0-2.78,2.15,20.73,20.73,0,0,0-.86,6.63V46.3c0,1.06,0,1.1.66,1.1h4.47A1.2,1.2,0,0,1,83.47,49Z" />
      <path d="M100.24,55c0,4.9-3.37,8.43-8.27,8.43a7.75,7.75,0,0,1-8-8.08,8.23,8.23,0,0,1,8.34-8.46A7.83,7.83,0,0,1,100.24,55ZM91.81,48c-2.58,0-4.5,2.47-4.5,6.77,0,3.57,1.6,7.41,5,7.41s4.51-3.6,4.51-6.62C96.87,52.26,95.53,48,91.81,48Z" />
      <path d="M104.66,51.59c0-1.53,0-1.72-1.06-2.43l-.35-.23a.53.53,0,0,1,0-.71,29.75,29.75,0,0,0,4-1.92c.19,0,.31.12.31.28v2.27c0,.19,0,.31.12.35,1.37-1.13,2.86-2.35,4.31-2.35a1.62,1.62,0,0,1,1.76,1.57,1.74,1.74,0,0,1-1.68,1.8,1.54,1.54,0,0,1-.86-.27,3.17,3.17,0,0,0-1.73-.63,1.73,1.73,0,0,0-1.41.78,6,6,0,0,0-.51,3v5.33c0,2.82.08,3.29,1.57,3.41l1.41.12a.69.69,0,0,1-.08,1c-1.84-.08-3-.12-4.35-.12s-2.58,0-3.6.12a.68.68,0,0,1-.08-1l.74-.12c1.45-.24,1.49-.59,1.49-3.41Z" />
      <path d="M150.14,53.75c-.27-.87-.43-1-1.6-1h-5.92c-1,0-1.21.11-1.49.94l-1.37,4.11a14.88,14.88,0,0,0-.86,3.25c0,.47.19.71,1.13.79l1.3.12a.64.64,0,0,1-.08,1c-1-.08-2.23-.08-4.08-.12-1.29,0-2.7.08-3.76.12a.79.79,0,0,1-.08-1l1.3-.12A2.61,2.61,0,0,0,137,59.94c.7-1.8,1.8-4.35,3.45-8.74l4-10.58a5.6,5.6,0,0,0,.67-2.78,4.44,4.44,0,0,0,2-1.49.55.55,0,0,1,.58.35c.51,1.73,1.14,3.53,1.73,5.25l5.37,15.76c1.13,3.36,1.64,3.95,3.33,4.15l1.06.12c.27.19.19.82,0,1-1.61-.08-3-.12-4.75-.12s-3.37.08-4.5.12a.66.66,0,0,1-.08-1l1.14-.12c.82-.08,1.37-.32,1.37-.71a8.16,8.16,0,0,0-.51-2.07Zm-7.75-3.49c-.32.9-.28.94.82.94H148c1.13,0,1.21-.16.86-1.21l-2.12-6.39a23.81,23.81,0,0,0-.9-2.47h-.08c-.11.2-.5,1.14-.94,2.31Z" />
      <path d="M161.66,51.59c0-1.53,0-1.72-1.06-2.43l-.35-.23a.53.53,0,0,1,0-.71,29.75,29.75,0,0,0,4-1.92c.19,0,.31.12.31.28v2.27c0,.19,0,.31.12.35,1.37-1.13,2.86-2.35,4.31-2.35a1.62,1.62,0,0,1,1.76,1.57,1.74,1.74,0,0,1-1.68,1.8,1.58,1.58,0,0,1-.87-.27,3.12,3.12,0,0,0-1.72-.63,1.73,1.73,0,0,0-1.41.78,6,6,0,0,0-.51,3v5.33c0,2.82.08,3.29,1.57,3.41l1.41.12a.69.69,0,0,1-.08,1c-1.84-.08-3-.12-4.35-.12s-2.59,0-3.6.12a.68.68,0,0,1-.08-1l.74-.12c1.45-.24,1.49-.59,1.49-3.41Z" />
      <path d="M177.41,49c-.67,0-.7.12-.7,1v7.32c0,2.28.27,4.35,2.86,4.35a3.12,3.12,0,0,0,1.05-.19c.36-.12.87-.55,1-.55.32,0,.47.43.4.66a5.42,5.42,0,0,1-4.12,1.81c-3.37,0-4.07-1.88-4.07-4.24v-9c0-1.06,0-1.14-.83-1.14h-1.09a.64.64,0,0,1-.12-.82A8,8,0,0,0,174.28,47a9.68,9.68,0,0,0,1.64-2.31c.2-.08.67-.08.82.19v1.76c0,.71,0,.75.71.75h4.35a1.25,1.25,0,0,1,0,1.64Z" />
      <path d="M192.34,47.56a9.17,9.17,0,0,1,.63,3.17.7.7,0,0,1-.95.12c-.66-1.73-1.84-2.86-3.44-2.86a2.32,2.32,0,0,0-2.51,2.39c0,1.6,1.65,2.47,3,3.25,2.07,1.17,4,2.51,4,5.29,0,3-2.74,4.47-5.64,4.47a6.54,6.54,0,0,1-4.19-1.18,11.43,11.43,0,0,1-.47-4,.67.67,0,0,1,.9,0c.67,2.16,1.92,4,4.15,4a2.42,2.42,0,0,0,2.63-2.51c0-1.8-1.57-2.78-3-3.6-2.27-1.3-3.88-2.67-3.88-4.82,0-2.86,2.35-4.47,5.33-4.47A6.83,6.83,0,0,1,192.34,47.56Z" />
      <path d="M216.11,42.07c0-2.74-.2-3.57-1.84-3.8l-.87-.12a.59.59,0,0,1,0-1c3.33-.19,6.51-.31,9.84-.31A24.59,24.59,0,0,1,231.9,38a11.74,11.74,0,0,1,7.95,11.56A12.86,12.86,0,0,1,233,61.27a19,19,0,0,1-8.78,1.88c-2.51,0-5-.31-6.39-.31-1.64,0-3.05,0-4.89.12a.75.75,0,0,1-.08-1l.9-.12c2.27-.32,2.35-.79,2.35-4.9Zm3.21,14c0,2.08.12,3.33.79,4.27.86,1.21,2.35,1.61,4.82,1.61,7.6,0,11-5,11-12.27,0-4.31-2.08-11.6-12.35-11.6a8.83,8.83,0,0,0-3.88.55c-.31.24-.39,1.22-.39,3Z" />
      <path d="M245.73,52.81c-1,0-1.06.07-1.06,1,0,4,2.31,7.33,6.51,7.33a4.21,4.21,0,0,0,3.6-1.92.52.52,0,0,1,.71.47,6.86,6.86,0,0,1-6.15,3.65,7,7,0,0,1-5.69-2.59A8.35,8.35,0,0,1,242,55.71c0-4.82,3.17-8.86,7.87-8.86,3.37,0,5.57,2.31,5.57,4.66a2.36,2.36,0,0,1-.16,1c-.12.24-.67.28-2.31.28Zm2.43-1.18c2.9,0,3.76-.16,4.07-.39.12-.08.24-.24.24-.74,0-1.1-.74-2.47-3-2.47s-4.31,2.11-4.35,3.41c0,.07,0,.19.28.19Z" />
      <path d="M266.1,47.56a9,9,0,0,1,.63,3.17.69.69,0,0,1-.94.12C265.12,49.12,264,48,262.34,48a2.32,2.32,0,0,0-2.51,2.39c0,1.6,1.65,2.47,3,3.25,2.08,1.17,4,2.51,4,5.29,0,3-2.74,4.47-5.64,4.47a6.55,6.55,0,0,1-4.2-1.18,11.67,11.67,0,0,1-.47-4,.68.68,0,0,1,.9,0c.67,2.16,1.92,4,4.16,4a2.41,2.41,0,0,0,2.62-2.51c0-1.8-1.56-2.78-3-3.6-2.28-1.3-3.88-2.67-3.88-4.82,0-2.86,2.35-4.47,5.33-4.47A6.82,6.82,0,0,1,266.1,47.56Z" />
      <path d="M271.08,51.59c0-1.53,0-1.72-1.06-2.43l-.35-.23a.53.53,0,0,1,0-.71,26.31,26.31,0,0,0,4-1.92.37.37,0,0,1,.4.24c-.08,1.37-.16,3.25-.16,4.86v7.05c0,2.82.08,3.21,1.49,3.41l.82.12a.69.69,0,0,1-.08,1c-1.17-.08-2.31-.12-3.68-.12s-2.59,0-3.68.12a.68.68,0,0,1-.08-1l.82-.12c1.45-.2,1.49-.59,1.49-3.41Zm3.25-11.17a2,2,0,0,1-4.07.08,2,2,0,0,1,2.11-2.11A2,2,0,0,1,274.33,40.42Z" />
      <path d="M294,48.26c.43.2.35,1.53-.23,1.61h-2.83a16.64,16.64,0,0,1,.08,2c0,2.7-1.6,6.23-6.58,6.23-.71,0-1.25-.08-1.72-.12-.47.27-1.73,1-1.73,2.15,0,.91.9,1.65,2.74,1.65,1.34,0,2.94-.12,4.67-.12,2.19,0,5.48.39,5.48,4.31,0,4.27-4.66,7.41-9.75,7.41-4.82,0-6.51-2.59-6.51-4.55a2.26,2.26,0,0,1,.43-1.45c.67-.7,1.77-1.72,2.67-2.62.35-.35.66-.71.31-.94a3.64,3.64,0,0,1-2.86-3.1c0-.19.16-.35.59-.66a12,12,0,0,0,2.07-1.81,1.47,1.47,0,0,0,.4-.74,5.31,5.31,0,0,1-2.94-4.74,6.12,6.12,0,0,1,6.38-5.92,9.9,9.9,0,0,1,3.77.78,4.66,4.66,0,0,0,2.39.63Zm-8.19,15.83a8.73,8.73,0,0,0-3.37.43,4,4,0,0,0-1.88,3.29c0,2.12,1.69,3.88,5.25,3.88s5.8-2,5.8-4.19c0-2.39-1.65-3.41-4.47-3.41Zm2.43-11.21c0-2.66-1.1-4.89-3.49-4.89-1.88,0-3.29,1.72-3.29,4.39S283.15,57,285,57C287.14,57,288.24,55.28,288.24,52.88Z" />
      <path d="M297.06,51.59c0-1.53,0-1.72-1.06-2.43l-.36-.23a.56.56,0,0,1,0-.71,20.93,20.93,0,0,0,3.92-1.92.45.45,0,0,1,.43.28c-.08.9-.08,1.49-.08,1.84a.42.42,0,0,0,.44.43,10.69,10.69,0,0,1,5.48-2c3.25,0,4.59,2.23,4.59,4.86v6.74c0,2.82.07,3.21,1.48,3.41l.87.12a.69.69,0,0,1-.08,1c-1.18-.08-2.31-.12-3.68-.12s-2.59,0-3.65.12a.69.69,0,0,1-.08-1l.75-.12c1.45-.24,1.49-.59,1.49-3.41V52.61c0-2.23-1-3.88-3.69-3.88a4.29,4.29,0,0,0-3.33,1.37,3.31,3.31,0,0,0-.59,2.35v6c0,2.82.08,3.17,1.49,3.41l.71.12a.69.69,0,0,1-.08,1c-1.06-.08-2.19-.12-3.57-.12s-2.58,0-3.68.12a.69.69,0,0,1-.08-1l.83-.12c1.45-.2,1.49-.59,1.49-3.41Z" />
      <path d="M12.82,94.62a12.25,12.25,0,0,1-6.11-1.45,18.24,18.24,0,0,1-1.06-5.49c.2-.27.79-.35.94-.12.59,2,2.2,5.84,6.74,5.84,3.29,0,4.9-2.15,4.9-4.51,0-1.72-.35-3.64-3.21-5.48L11.29,81c-1.95-1.29-4.23-3.53-4.23-6.74,0-3.72,2.9-6.74,8-6.74A14.87,14.87,0,0,1,18.7,68a5.11,5.11,0,0,0,1.37.23A17.25,17.25,0,0,1,20.78,73c-.16.24-.79.36-1,.12-.51-1.88-1.57-4.43-5.33-4.43s-4.66,2.55-4.66,4.35c0,2.28,1.88,3.92,3.33,4.82l3.13,2c2.47,1.53,4.9,3.8,4.9,7.53C21.17,91.68,17.92,94.62,12.82,94.62Z" />
      <path d="M39.82,86.11c0,4.9-3.37,8.43-8.27,8.43a7.75,7.75,0,0,1-8-8.08A8.23,8.23,0,0,1,31.86,78,7.83,7.83,0,0,1,39.82,86.11Zm-8.43-6.93c-2.58,0-4.5,2.47-4.5,6.78,0,3.56,1.6,7.4,5.05,7.4s4.51-3.6,4.51-6.62C36.45,83.41,35.12,79.18,31.39,79.18Z" />
      <path d="M55.18,78.67a2.22,2.22,0,0,1,.58,1.56c0,.71-.39,1.45-.86,1.45a.68.68,0,0,1-.55-.27,5.33,5.33,0,0,0-3.91-2.08c-2.75,0-5.41,2.16-5.41,6.31,0,3.06,2.11,6.7,6.31,6.7A4.8,4.8,0,0,0,55.69,90a.62.62,0,0,1,.7.55,7.14,7.14,0,0,1-6.54,4,7.6,7.6,0,0,1-5.37-2,7.8,7.8,0,0,1-2.27-5.8c0-4.23,3-8.74,9.48-8.74A9.81,9.81,0,0,1,55.18,78.67Z" />
      <path d="M59.92,82.74c0-1.53,0-1.72-1.06-2.43l-.36-.23a.56.56,0,0,1,0-.71,26.41,26.41,0,0,0,4-1.92.36.36,0,0,1,.39.24c-.08,1.37-.16,3.25-.16,4.86v7c0,2.82.08,3.21,1.49,3.41l.83.12a.69.69,0,0,1-.08,1C63.87,94,62.74,94,61.37,94s-2.59,0-3.69.12a.69.69,0,0,1-.08-1l.83-.12c1.45-.2,1.49-.59,1.49-3.41Zm3.25-11.17a2,2,0,0,1-4.08.08,2,2,0,0,1,2.12-2.11A2,2,0,0,1,63.17,71.57Z" />
      <path d="M75,85.88c.59-.2.67-.55.67-1.14l0-1.8c0-1.45-.43-3.41-2.86-3.41-1.09,0-2.43.55-2.5,1.76,0,.63-.08,1.1-.4,1.34a4.26,4.26,0,0,1-2.23.74.5.5,0,0,1-.55-.55c0-2,4.43-4.82,7.29-4.82s4.19,1.65,4.08,4.59l-.24,6.81c-.08,1.81.27,3.41,1.8,3.41a2.21,2.21,0,0,0,1-.23c.2-.12.39-.28.51-.28s.28.24.28.51a4.12,4.12,0,0,1-3.3,1.73,3.12,3.12,0,0,1-2.54-1.22c-.16-.23-.39-.55-.63-.55a6.35,6.35,0,0,0-1.53.79,5.78,5.78,0,0,1-2.82,1,4.09,4.09,0,0,1-2.74-.9,3.67,3.67,0,0,1-1.22-2.75c0-1.53.75-2.43,3.26-3.33Zm-2.43,6.89a4.38,4.38,0,0,0,2.39-1c.47-.47.47-1,.51-1.84l.08-2.31c0-.43-.16-.51-.39-.51a7.55,7.55,0,0,0-1.61.39c-2.31.79-3.45,1.37-3.45,2.9A2.36,2.36,0,0,0,72.61,92.77Z" />
      <path d="M87.54,89.6c0,2.82.08,3.21,1.49,3.41l.94.12a.69.69,0,0,1-.08,1c-1.3-.08-2.43-.12-3.8-.12s-2.59,0-3.76.12a.68.68,0,0,1-.08-1l.9-.12c1.45-.2,1.49-.59,1.49-3.41V71.89c0-2.31,0-2.55-1.22-3.25L83,68.4a.58.58,0,0,1,0-.78A27.93,27.93,0,0,0,87.26,66a.42.42,0,0,1,.39.24c0,1.17-.11,3.13-.11,4.86Z" />
      <path d="M239.7,73.37c0-3.72-.12-4.39-1.72-4.58l-1.26-.16a.66.66,0,0,1,0-1c2.2-.19,4.9-.31,8.74-.31A13.85,13.85,0,0,1,252,68.51,5.84,5.84,0,0,1,255.3,74c0,3.72-2.94,5.76-4.9,6.54-.2.24,0,.63.2.94,3.13,5.06,5.21,8.19,7.87,10.43a4.7,4.7,0,0,0,2.7,1.09.33.33,0,0,1,0,.55,6,6,0,0,1-1.72.2c-3.33,0-5.33-1-8.11-4.94-1-1.45-2.63-4.15-3.84-5.92a2.8,2.8,0,0,0-2.78-1.25c-1.77,0-1.85,0-1.85.86v4.94c0,4.11.08,4.59,2.36,4.9l.82.12a.77.77,0,0,1-.08,1c-1.76-.08-3.1-.12-4.66-.12s-3.06,0-4.9.12a.76.76,0,0,1-.08-1l1-.12c2.27-.28,2.35-.79,2.35-4.9ZM242.91,79a3,3,0,0,0,.12,1.29c.12.12.71.19,2.71.19a6.55,6.55,0,0,0,4-1c1.06-.83,2-2.2,2-4.86,0-3.1-1.92-6-6.27-6-2.43,0-2.55.16-2.55,1.26Z" />
      <path d="M264,83.29c-1,0-1.06.08-1.06,1,0,4,2.31,7.33,6.51,7.33a4.21,4.21,0,0,0,3.6-1.92.52.52,0,0,1,.71.47,6.86,6.86,0,0,1-6.16,3.65,7,7,0,0,1-5.68-2.59,8.35,8.35,0,0,1-1.64-5.09c0-4.82,3.17-8.86,7.87-8.86,3.37,0,5.57,2.31,5.57,4.66a2.36,2.36,0,0,1-.16,1c-.12.24-.66.28-2.31.28Zm2.43-1.18c2.9,0,3.76-.15,4.07-.39.12-.08.24-.24.24-.75,0-1.09-.74-2.46-3-2.46s-4.31,2.11-4.35,3.41c0,.07,0,.19.28.19Z" />
      <path d="M284.37,78a9,9,0,0,1,.63,3.17.69.69,0,0,1-.94.12c-.67-1.73-1.84-2.86-3.45-2.86a2.32,2.32,0,0,0-2.51,2.39c0,1.6,1.65,2.47,3,3.25,2.08,1.18,4,2.51,4,5.29,0,3-2.74,4.47-5.64,4.47a6.55,6.55,0,0,1-4.2-1.18,11.67,11.67,0,0,1-.47-4,.68.68,0,0,1,.9,0c.67,2.16,1.92,4,4.16,4a2.41,2.41,0,0,0,2.62-2.51c0-1.8-1.56-2.78-3-3.6-2.28-1.3-3.88-2.67-3.88-4.82,0-2.86,2.35-4.47,5.33-4.47A6.82,6.82,0,0,1,284.37,78Z" />
      <path d="M290.6,83.29c-1,0-1.05.08-1.05,1,0,4,2.31,7.33,6.5,7.33a4.21,4.21,0,0,0,3.6-1.92.52.52,0,0,1,.71.47,6.84,6.84,0,0,1-6.15,3.65,7,7,0,0,1-5.68-2.59,8.3,8.3,0,0,1-1.65-5.09c0-4.82,3.17-8.86,7.88-8.86,3.37,0,5.56,2.31,5.56,4.66a2.36,2.36,0,0,1-.16,1c-.11.24-.66.28-2.31.28ZM293,82.11c2.9,0,3.76-.15,4.08-.39.12-.08.23-.24.23-.75,0-1.09-.74-2.46-3-2.46S290,80.62,290,81.92c0,.07,0,.19.27.19Z" />
      <path d="M309.53,85.21c.58-.2.66-.55.66-1.14l0-1.8c0-1.45-.43-3.41-2.86-3.41-1.1,0-2.43.55-2.51,1.76,0,.63-.07,1.1-.39,1.34a4.29,4.29,0,0,1-2.23.74.5.5,0,0,1-.55-.55c0-2,4.43-4.82,7.29-4.82s4.19,1.65,4.07,4.59l-.23,6.81c-.08,1.81.27,3.41,1.8,3.41a2.21,2.21,0,0,0,1-.23c.2-.12.39-.28.51-.28s.27.24.27.51a4.1,4.1,0,0,1-3.29,1.73,3.12,3.12,0,0,1-2.54-1.22c-.16-.23-.4-.55-.63-.55a6.17,6.17,0,0,0-1.53.79,5.78,5.78,0,0,1-2.82,1,4,4,0,0,1-2.74-.91,3.63,3.63,0,0,1-1.22-2.74c0-1.53.75-2.43,3.25-3.33ZM307.1,92.1a4.38,4.38,0,0,0,2.39-1c.47-.47.47-1,.51-1.84l.08-2.31c0-.43-.16-.51-.4-.51a7.65,7.65,0,0,0-1.6.39c-2.31.79-3.45,1.37-3.45,2.9A2.36,2.36,0,0,0,307.1,92.1Z" />
      <path d="M319.13,82.07c0-1.53,0-1.72-1.06-2.43l-.36-.23a.56.56,0,0,1,0-.71,29.75,29.75,0,0,0,4-1.92c.2,0,.31.12.31.28v2.27c0,.2,0,.31.12.35,1.37-1.14,2.86-2.35,4.31-2.35a1.63,1.63,0,0,1,1.77,1.57,1.74,1.74,0,0,1-1.69,1.8,1.54,1.54,0,0,1-.86-.27,3.25,3.25,0,0,0-1.73-.63,1.76,1.76,0,0,0-1.41.78,6,6,0,0,0-.51,3v5.33c0,2.82.08,3.29,1.57,3.41l1.41.12a.68.68,0,0,1-.08,1c-1.84-.08-3-.12-4.34-.12s-2.59,0-3.61.12a.69.69,0,0,1-.08-1l.75-.12c1.45-.24,1.49-.59,1.49-3.41Z" />
      <path d="M341.65,78a2.18,2.18,0,0,1,.59,1.56c0,.71-.39,1.45-.86,1.45a.68.68,0,0,1-.55-.27,5.33,5.33,0,0,0-3.92-2.08c-2.74,0-5.4,2.16-5.4,6.31,0,3.06,2.11,6.7,6.3,6.7a4.77,4.77,0,0,0,4.35-2.35.62.62,0,0,1,.71.55,7.14,7.14,0,0,1-6.54,4,7.6,7.6,0,0,1-5.37-2,7.8,7.8,0,0,1-2.27-5.8c0-4.23,3-8.74,9.48-8.74A9.72,9.72,0,0,1,341.65,78Z" />
      <path d="M345.61,71.22c0-2.31,0-2.55-1.21-3.26l-.4-.23a.6.6,0,0,1,0-.78,28.41,28.41,0,0,0,4.24-1.65.44.44,0,0,1,.39.24c0,1.17-.12,3.13-.12,4.85v8.23c0,.36,0,.67.16.75a8.88,8.88,0,0,1,5.29-2c3.72,0,5.05,2.31,5.05,5v6.55c0,2.82.08,3.25,1.49,3.41l1.06.12a.69.69,0,0,1-.08,1c-1.41-.08-2.55-.12-3.92-.12s-2.58,0-3.64.12a.68.68,0,0,1-.08-1l.78-.12c1.45-.24,1.49-.59,1.49-3.41v-5.6c0-2.51-1.21-4.2-3.72-4.2a4.7,4.7,0,0,0-3.06,1.06c-.7.59-.82.78-.82,2.16v6.58c0,2.82.08,3.21,1.49,3.41l.82.12c.28.15.2.86-.08,1-1.17-.08-2.31-.12-3.68-.12s-2.59,0-3.84.12a.69.69,0,0,1-.08-1l1-.12c1.45-.16,1.49-.59,1.49-3.41Z" />
    </g>
  </svg>
);

export default Logo;