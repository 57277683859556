import Config from './config'

export default {
  getResidencies (params) {
    const requestURL = `${Config.API_URL}/residencies`
    const req = {
      method: 'get',
      url: requestURL,
      populate: 'imagecaptions',
      params
    }

    return fetch(req.url, req).then((response) => {
      return response.json()
    })
  },

  getFellows (params) {
    const requestURL = `${Config.API_URL}/fellows`
    const req = {
      method: 'get',
      url: requestURL,
      params
    }

    return fetch(req.url, req).then((response) => {
      return response.json()
    })
  },

  getProjects (params) {
    const requestURL = `${Config.API_URL}/projects`
    const req = {
      method: 'get',
      url: requestURL,
      params
    }

    return fetch(req.url, req).then((response) => {
      return response.json()
    })
  },

  getHappenings (params) {
    const requestURL = `${Config.API_URL}/happenings`
    const req = {
      method: 'get',
      url: requestURL,
      params
    }

    return fetch(req.url, req).then((response) => {
      return response.json()
    })
  },

  getResidencySlides (residency) {
    const requestURL = `${Config.API_URL}/imagecaptions/`
    const promises = residency.imagecaptions.map(obj => fetch(requestURL + obj.id).then(y => y.json()))
    return Promise.all(promises)
  },

  getPage (pageTitle) {
    const requestURL = `${Config.API_URL}/pages`
    const pageTitleQuery = `?title_contains=${pageTitle}`
    const req = {
      method: 'get',
      url: requestURL + pageTitleQuery
    }

    return fetch(req.url, req).then((response) => {
      return response.json()
    })
  },

  getWorkshops (params) {
    const requestURL = `${Config.API_URL}/workshops`
    const req = {
      method: 'get',
      url: requestURL,
      populate: 'imagecaptions',
      params
    }

    return fetch(req.url, req).then((response) => {
      return response.json()
    })
  },

  getWorkshopSlides (workshop) {
    const requestURL = `${Config.API_URL}/imagecaptions/`
    const promises = workshop.imagecaptions.map(obj => fetch(requestURL + obj.id).then(y => y.json()))
    return Promise.all(promises)
  },

  getGallery (galleryTitle) {
    const requestURL = `${Config.API_URL}/galleries`
    const galleryTitleQuery = `?title_contains=${galleryTitle}`
    const req = {
      method: 'get',
      url: requestURL + galleryTitleQuery,
      populate: 'imagecaptions'
    }

    return fetch(req.url, req).then((response) => {
      return response.json()
    })
  },

  getPageDescriptions (params) {
    const requestURL = `${Config.API_URL}/pagedescriptions`
    const req = {
      method: 'get',
      url: requestURL,
      params
    }

    return fetch(req.url, req).then((response) => (
      response.json()
    ))
  },

  getSingleType (id) {
    const requestURL = `${Config.API_URL}/${id}`
    const req = {
      method: 'get',
      url: requestURL
    }

    return fetch(req.url, req).then((response) => (
      response.json()
    ))
  }
}
