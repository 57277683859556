import style from './style';
import classNames from 'classnames/bind';

let cx = classNames.bind(style);

const BurgerIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 57.09 35" style={{ fill: props.fill }}>
    <g>
      <rect width="57.09" height="2" />
      <rect y="16.5" width="57.09" height="2" />
      <rect y="33" width="57.09" height="2" />
    </g>
  </svg>
);
  
export default BurgerIcon;