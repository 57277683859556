export const constructTopNav = (fellows, year, includeSubNav) => {
  let research = null;
  let srResearch =  null;
  let faculty = null;
  
  year = year || '';
  if (includeSubNav) {
    
    research = [];
    srResearch = [];
    faculty = [];
    
    for (let fellow of fellows) {
      if (fellow.position.trim() === 'Research') {
        research.push({
          title: fellow.name,
          link: `/fellows/${year}?position=Research#${fellow.id}`
        });
      }
      else if (fellow.position.trim() === 'Sr Research') {
        srResearch.push({
          title: fellow.name,
          link: `/fellows/${year}?position=${encodeURIComponent('Sr Research')}#${fellow.id}`
        });
      }
      else if (fellow.position.trim() === 'Faculty') {
        faculty.push({
          title: fellow.name,
          link: `/fellows/${year}?position=Faculty#${fellow.id}`
        });
      }
    }
  }
  
  return [
    {
      title: 'Research',
      link: `/fellows/${year ? year + '/' : ''}?position=Research`,
      subNavItems: research
    },
    {
      title: 'Sr Research',
      link: `/fellows/${year ? year + '/' : ''}?position=Sr Research`,
      subNavItems: srResearch
    },
    {
      title: 'Faculty',
      link: `/fellows/${year ? year + '/' : ''}?position=Faculty`,
      subNavItems: faculty
    }
  ];
};