import Utils from '../../utils';

export const constructTopNav = (data, basePath, contentType) => {
  const topNav = data.map((item, index) => {
    const link = `/${basePath}/${encodeURIComponent(item.title)}`;
    return {
      title: `${contentType} ${Utils.romanize(index+1)}`,
      link
    };
  });

  return topNav;
};