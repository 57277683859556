import { h, Component } from 'preact'
import { useState, useRef, useEffect } from 'preact/compat'
import useOnClickOutside from 'use-onclickoutside'
import style from './style'
import classNames from 'classnames/bind'
import { Link, Match } from 'preact-router/match'
import mobile from 'is-mobile'
import TextJank from '../text-jank'
import Utils from '../../utils'
import HomeIcon from '../home-icon'
import CloseIcon from '../close-icon'
import Logo from '../logo'
import BurgerIcon from '../burger-icon'

const cx = classNames.bind(style)

export const MOBILE_NAV_HEADER_COLORS = {
  white: 'rgba(255, 255, 255, 1)',
  transparent: 'rgba(0, 0, 0, 0)',
  darkGray: '#333333'
}

const SecondaryNavItem = ({ title, to, onClick }) => (
  <div class={cx('nav-bar-item', 'nav-bar-item--secondary')}>
    <Link
      onClick={onClick}
      class={cx('nav-bar-item__link')}
      activeClassName={cx('nav-bar-item__link--active')}
      href={to}
    >
      {title}
    </Link>
  </div>
)

const PrimaryNavItem = ({ title, to, hovered, onClick, onPointerEnter, onPointerLeave, subNavItems }) => (
  <Match path={to}>
    {({ path }) => {
      const active = hovered || path.split('/')[1] === to
      return (
        <div
          class={cx('nav-bar-item', 'nav-bar-item--primary', { 'nav-bar-item--active': active })}
          onPointerEnter={onPointerEnter}
          onPointerLeave={onPointerLeave}
        >
          <Link onClick={onClick} href={to} class={cx('nav-bar-item__link', { 'nav-bar-item__link--active': active })}>
            <TextJank jank={active && 6}>
              {title}
            </TextJank>
          </Link>
          <div class={cx('nav-bar__sub-section', { 'nav-bar__sub-section--active': active })}>
            {subNavItems}
          </div>
        </div>
      )
    }}
  </Match>
)

const SubNavItem = ({ title, to, onClick }) => (
  <div class={cx('nav-bar-item', 'nav-bar-item--sub')}>
    <Link
      onClick={onClick}
      class={cx('nav-bar-item__link')}
      activeClassName={cx('nav-bar-item__link--active')}
      href={to}
    >
      {title}
    </Link>
  </div>
)

const MainNav = ({ residencies, fellows, projects, workshops, onPageLinkHover, mobileHeaderBgColor }) => {
  // State
  const [collapsed, setCollapsed] = useState(true)
  const [hoveredItem, setHoveredItem] = useState(null)

  // Close the nav bar if we click outside of it
  const node = useRef()
  useOnClickOutside(node, () => setCollapsed(true))

  // Secondary nav items
  const secondaryNavItems = [
    { title: 'About', to: '/about' },
    { title: 'Happenings', to: '/happenings' },
    { title: 'Participate', to: '/participate' },
    { title: 'Support', to: '/support' }
  ]

  // Sub nav items set up
  const residenciesSubNavItems = residencies.map((residency, index) => (
    <SubNavItem
      title={Utils.romanize(index + 1)}
      to={`/residencies/${encodeURIComponent(residency.title)}`}
      onClick={() => setCollapsed(true)}
    />
  ))

  const workshopsSubNavItems = workshops.map((workshop, index) => (
    <SubNavItem
      title={Utils.romanize(index + 1)}
      to={`/workshops/${encodeURIComponent(workshop.title)}`}
      onClick={() => setCollapsed(true)}
    />
  ))

  const parseFellowsYears = (fellows) => {
    const years = []
    for (const fellow of fellows) {
      years.push(...Utils.parseFellowYears(fellow))
    }
    return Array.from(new Set(years))
  }

  const fellowsSubNavItems = parseFellowsYears(fellows).map((year) => (
    <SubNavItem
      title={year}
      to={`/fellows/${encodeURIComponent(year)}`}
      onClick={() => setCollapsed(true)}
    />
  ))

  const projectsSubNavItems = projects.map((project) => (
    <SubNavItem
      title={`Project ${Utils.romanize(project.workingGroupNumber)}`}
      to={`/projects/${encodeURIComponent(project.title)}`}
      onClick={() => setCollapsed(true)}
    />
  ))

  // Primary nav items
  // This is weird, we have a hovered item state both here and in app.
  // We need it in app so that we can show the proper section descriptions
  // but it feels weird to have it only there an not in nav bar. It's state
  // that's replicated though, so definitely bad.
  useEffect(() => onPageLinkHover(hoveredItem), [hoveredItem])

  // TODO: Maybe refactor so I don't need to include onClick, onPointerEnter, onPointerLeave
  const primaryNavItems = [
    {
      title: 'Fellows',
      to: '/fellows',
      hovered: hoveredItem === 'fellows',
      onClick: () => setCollapsed(true),
      onPointerEnter: () => setHoveredItem('fellows'),
      onPointerLeave: () => setHoveredItem(null),
      subNavItems: fellowsSubNavItems
    },
    {
      title: 'Research',
      to: '/projects',
      hovered: hoveredItem === 'research',
      onClick: () => setCollapsed(true),
      onPointerEnter: () => setHoveredItem('research'),
      onPointerLeave: () => setHoveredItem(null),
      subNavItems: projectsSubNavItems
    },
    {
      title: 'Residencies',
      to: '/residencies',
      hovered: hoveredItem === 'residencies',
      onClick: () => setCollapsed(true),
      onPointerEnter: () => setHoveredItem('residencies'),
      onPointerLeave: () => setHoveredItem(null),
      subNavItems: residenciesSubNavItems
    },
    {
      title: 'Workshops',
      to: '/workshops',
      hovered: hoveredItem === 'workshops',
      onClick: () => setCollapsed(true),
      onPointerEnter: () => setHoveredItem('workshops'),
      onPointerLeave: () => setHoveredItem(null),
      subNavItems: workshopsSubNavItems
    }
  ]

  return (
    <div ref={node}>
      {/* Desktop Nav */}
      {!mobile({ tablet: true }) && <nav class={cx('nav-bar', 'nav-bar--desktop', { 'nav-bar--collapsed': collapsed })}>
        <div class={cx('nav-bar__opener')} onClick={() => setCollapsed(!collapsed)}>
          <div class={cx('nav-bar__tab')}>
            <span class={cx('nav-bar__tab-text')}>MENU</span>
          </div>
        </div>
        <div class={cx('nav-bar__section', 'nav-bar__section--secondary', { 'nav-bar__section--collapsed': collapsed })}>
          <Match>
            {({ path }) => (
              <Link href='/' class={cx('nav-bar__home-icon')}>
                <HomeIcon path={path} />
              </Link>
            )}
          </Match>
          <div class={cx('nav-bar__positioner', 'nav-bar__positioner--bottom')}>
            {secondaryNavItems.map(navItem => (
              <SecondaryNavItem title={navItem.title} to={navItem.to} onClick={() => setCollapsed(true)} />
            ))}
          </div>
        </div>
        <div class={cx('nav-bar__section', 'nav-bar__section--primary', { 'nav-bar__section--collapsed': collapsed })}>
          {primaryNavItems.map(navItem => (
            <PrimaryNavItem {...navItem} />
          ))}
        </div>
      </nav>}
      {/* Mobile Nav */}
      {mobile({ tablet: true }) &&
        <>
          <div class={cx('nav-drawer-header')} style={{ background: mobileHeaderBgColor }}>
            <div class={cx('nav-drawer-header__logo')}>
              <Logo fill={mobileHeaderBgColor === MOBILE_NAV_HEADER_COLORS.darkGray ? '#fff' : '#000'} />
            </div>
            <div class={cx('nav-drawer-header__burger')} onClick={() => setCollapsed(false)}>
              <BurgerIcon fill={mobileHeaderBgColor === MOBILE_NAV_HEADER_COLORS.darkGray ? 'fff' : '000'} />
            </div>
          </div>
          <div class={cx('nav-drawer', { 'nav-drawer--collapsed': collapsed })}>
            <div class={cx('nav-drawer__top')}>
              <Link href='/' class={cx('nav-drawer__home-logo')} onClick={() => setCollapsed(true)}>
                <img src='https://byoshkitchensink.nyc3.cdn.digitaloceanspaces.com/cadsr_nav_logo_white.svg' />
              </Link>
              <div class={cx('nav-drawer__close')} onClick={() => setCollapsed(true)}>
                <CloseIcon color='#fff' />
              </div>
            </div>
            <div class={cx('nav-drawer__items')}>
              <Link href='/description/fellows' class={cx('nav-drawer-item')} onClick={() => setCollapsed(true)}>
                Fellows
              </Link>
              <Link href='/description/projects' class={cx('nav-drawer-item')} onClick={() => setCollapsed(true)}>
                Research
              </Link>
              <Link href='/description/residencies' class={cx('nav-drawer-item')} onClick={() => setCollapsed(true)}>
                Residencies
              </Link>
              <Link href='/description/workshops' class={cx('nav-drawer-item')} onClick={() => setCollapsed(true)}>
                Workshops
              </Link>
              <Link href='/happenings' class={cx('nav-drawer-item')} onClick={() => setCollapsed(true)}>
                Happenings
              </Link>
              <Link href='/about' class={cx('nav-drawer-item')} onClick={() => setCollapsed(true)}>
                About
              </Link>
              <Link href='/participate' class={cx('nav-drawer-item')} onClick={() => setCollapsed(true)}>
                Participate
              </Link>
              <Link href='/support' class={cx('nav-drawer-item')} onClick={() => setCollapsed(true)}>
                Support
              </Link>
            </div>
          </div>
        </>}
    </div>
  )
}

export default MainNav
