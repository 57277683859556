import style from './style';
import classNames from 'classnames/bind';
import Match from 'preact-router/match';

let cx = classNames.bind(style);

export default class HomeIcon {
  
  shouldComponentUpdate(nextProps) {
    return nextProps.path && this.props.path && nextProps.path.split('/')[1] !== this.props.path.split('/')[1];
  }
  
  render() {
    return (<Match>
      {({ path }) => {
        const topLevelPath = path.split('/')[1];
        if (topLevelPath !== '') {
          const homeIconRand = Math.floor(Math.random() * 3);
          return (<div class={cx('home-icon', {
            'home-icon--half-circle': homeIconRand === 0,
            'home-icon--diagonal': homeIconRand === 1,
            'home-icon--corner': homeIconRand === 2
          })} />);
        }
      }}
    </Match>);
  }
}