import { h, toChildArray } from 'preact';
import { useState, useEffect } from 'preact/hooks'
import style from './style';
import classNames from 'classnames/bind';
import utils from '../../utils';

let cx = classNames.bind(style);

const TextJank = ({ jank, children }) => {

  // shouldComponentUpdate(nextProps, nextState) {
  //   return this.props.jank !== nextProps.jank || !utils.arraysEqual(toChildArray(this.props.children), toChildArray(nextProps.children));
  // }
  
  // render({ children, jank }) {
  const [janked, setJanked] = useState('');
  useEffect(() => {
    if (children && jank) {
      setJanked(children.split('').map((letter) => {
        const marginRight = Math.floor(Math.random() * jank)  + 'px';
        return <span style={{ marginRight }}>{letter}</span>;
      }))
    } else {
      setJanked(children) 
    }
    // setJanked(children[0].split('').map((letter) => {
    //   const marginRight = Math.floor(Math.random() * jank)  + 'px';
    //   return <span style={{ marginRight }}>{letter}</span>;
    // }));
  }, [jank, children])

    // let janked = children || '';
    // if (children[0] && jank) {
    //   janked = children[0].split('').map((letter) => {
    //     const marginRight = Math.floor(Math.random() * jank)  + 'px';
    //     return <span style={{ marginRight }}>{letter}</span>;
    //   });
    // }

    return (
      <div class={cx('text-jank')}>
        {janked}
      </div>
    );
  // }
}

export default TextJank