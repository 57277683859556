import Utils from '../../utils';

export const constructTopNav = (projects) => (
  projects.map((project) => (
    {
      title: `Project ${Utils.romanize(project.workingGroupNumber)}`,
      link: `/projects/${encodeURIComponent(project.title)}`,
      id: project.id
    }
  ))
);