import { h } from 'preact'
import style from './style'
import classNames from 'classnames/bind'
import mobile from 'is-mobile'
import Markdown from 'preact-markdown'

const cx = classNames.bind(style)

const PageDescription = ({ pageDescription }) => {
  return (
    <div class={cx('page-description', { 'page-description--mobile': mobile({ tablet: true }) })}>
      <h1 class={cx('page-description__title')}>
        {pageDescription && pageDescription.title.split('').map(letter => <span>{letter}</span>)}
      </h1>
      <div class={cx('page-description__text')}>
        <Markdown markdown={pageDescription && pageDescription.description || ''} />
      </div>
    </div>
  )
}

export default PageDescription
