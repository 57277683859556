
import { h, Component } from 'preact'
import { Router, route } from 'preact-router'
import classNames from 'classnames/bind'

import Api from '../api'
import config from '../api/config'
import utils from '../utils'

// Code-splitting is automated for routes
import Home from '../routes/home'
import Fellows from '../routes/fellows'
import { constructTopNav as constructFellowsTopNav } from '../routes/fellows/top-nav'

import Project from '../routes/project'
import { constructTopNav as constructProjectsTopNav } from '../routes/project/top-nav'
import About from '../routes/about'
import Happenings from '../routes/happenings'
import Support from '../routes/support'
import ContentSlideshowsPage from '../routes/content-slideshows-page'
import { constructTopNav as constructContentSlideshowsPageTopNav } from '../routes/content-slideshows-page/top-nav'

import MainNav from './nav-bar'
import PageDescription from '../components/page-description'
import mobile from 'is-mobile'

import style from './style'
import Description from '../routes/description'
import Participate from '../routes/participate'

const cx = classNames.bind(style)

class AsyncRedirect extends Component {
  componentDidUpdate (prevProps) {
    if (this.props.to) {
      route(this.props.to, true)
    }
  }

  render () {
    return null
  }
}

export default class App extends Component {
  /** Gets fired when the route changes.
   *	@param {Object} event		"change" event from [preact-router](http://git.io/preact-router)
   *	@param {string} event.url	The newly routed URL
   */
  handleRoute = e => {
    this.currentUrl = e.url
  };

  fetchData = () => {
    const dataPromises = [
      Api.getResidencies(),
      Api.getFellows(),
      Api.getProjects(),
      Api.getHappenings(),
      Api.getGallery('Home'),
      Api.getPage('support'),
      Api.getPage('participate'),
      Api.getWorkshops(),
      Api.getPageDescriptions()
    ]

    Promise.all(dataPromises).then(values => {
      this.setState({
        residencies: values[0],
        fellows: values[1],
        projects: values[2],
        happenings: values[3],
        homeGallery: values[4][0],
        support: values[5][0],
        participate: values[6][0],
        workshops: values[7],
        pageDescriptions: values[8]
      })
    })
  }

  // Couldn't come up with a better name for this
  // This is called by MainNav so that we can let
  // home know a page link is being hovered over
  // so that we can show/hide its description.
  setPageLinkHover = (page) => {
    this.setState({
      pageLinkHover: page
    })
  }

  setMobileNavHeaderBgColor = (color) => {
    this.setState({
      mobileNavHeaderBgColor: color
    })
  }

  constructor () {
    super()
    this.state = {
      residencies: [],
      fellows: [],
      projects: [],
      happenings: [],
      workshops: [],
      pageDescriptions: [],
      about: null,
      support: null,
      homeGallery: null,
      pageLinkHover: null,
      mobileNavHeaderBgColor: 'transparent'
    }
  }

  getChildContext () {
    return {
      host: config.API_URL,
      cloudinaryTransformation: '/c_limit,h_1500,q_auto:best,w_1500/f_jpg/'
    }
  }

  componentDidMount () {
    this.fetchData()
  }

  render () {
    const pageDescription = this.state.pageDescriptions.find(pageDescription => pageDescription.title.toLowerCase() === (this.state.pageLinkHover && this.state.pageLinkHover.toLowerCase()))
    const residenciesRedirectURL = this.state.residencies[0] ? `/residencies/${encodeURIComponent(this.state.residencies[0].title)}` : ''
    const projectsRedirectURL = this.state.projects[0] ? `/projects/${encodeURIComponent(this.state.projects[0].title)}` : ''
    const redirects = {
      residenciesRedirectURL,
      projectsRedirectURL
    }

    const fellowsPageDescription = this.state.pageDescriptions.find(pageDescription => pageDescription.title.toLowerCase() === 'fellows')
    const researchPageDescription = this.state.pageDescriptions.find(pageDescription => pageDescription.title.toLowerCase() === 'research')
    const residenciesPageDescription = this.state.pageDescriptions.find(pageDescription => pageDescription.title.toLowerCase() === 'residencies')
    const workshopsPageDescription = this.state.pageDescriptions.find(pageDescription => pageDescription.title.toLowerCase() === 'workshops')

    return (
      <div id='app' class={cx('app')}>
        <div class={cx('app__nav', { 'app__nav--mobile': mobile({ tablet: true }) })}>
          <MainNav
            residencies={this.state.residencies}
            fellows={this.state.fellows}
            workshops={this.state.workshops}
            projects={this.state.projects}
            onPageLinkHover={this.setPageLinkHover}
            mobileHeaderBgColor={this.state.mobileNavHeaderBgColor}
          />
        </div>
        <div class={cx('app__content')}>
          <div class={cx('app__container')}>
            <Router onChange={this.handleRoute}>
              <Home
                path='/' latest={utils.sortHappeningsByDate(this.state.happenings)[this.state.happenings.length - 1]}
                gallery={this.state.homeGallery} setMobileNavHeaderBgColor={this.setMobileNavHeaderBgColor}
              />

              <AsyncRedirect path='/residencies' to={this.state.residencies[0] && `/residencies/${encodeURIComponent(this.state.residencies[0].title)}`} />
              {(mobile({ tablet: true }) && <Description path='/description/residencies' topNavData={constructContentSlideshowsPageTopNav(this.state.residencies, 'residencies', 'Residency')} pageDescription={residenciesPageDescription} setMobileNavHeaderBgColor={this.setMobileNavHeaderBgColor} />) || <ContentSlideshowsPage data={this.state.residencies} contentType='Residency' path='/residencies/:title' setMobileNavHeaderBgColor={this.setMobileNavHeaderBgColor} />}
              <ContentSlideshowsPage data={this.state.residencies} contentType='Residency' path='/residencies/:title' setMobileNavHeaderBgColor={this.setMobileNavHeaderBgColor} />

              <AsyncRedirect path='/workshops' to={this.state.workshops[0] && `/workshops/${encodeURIComponent(this.state.workshops[0].title)}`} />
              {(mobile({ tablet: true }) && <Description path='/description/workshops' topNavData={constructContentSlideshowsPageTopNav(this.state.workshops, 'workshops', 'Workshop')} pageDescription={workshopsPageDescription} setMobileNavHeaderBgColor={this.setMobileNavHeaderBgColor} />) || <ContentSlideshowsPage data={this.state.workshops} contentType='Workshop' path='/workshops/:title' setMobileNavHeaderBgColor={this.setMobileNavHeaderBgColor} />}
              <ContentSlideshowsPage data={this.state.workshops} contentType='Workshop' path='/workshops/:title' setMobileNavHeaderBgColor={this.setMobileNavHeaderBgColor} />

              {(mobile({ tablet: true }) && <Description path='/description/fellows' topNavData={constructFellowsTopNav(this.state.fellows, null, false)} pageDescription={fellowsPageDescription} setMobileNavHeaderBgColor={this.setMobileNavHeaderBgColor} />) || <Fellows path='/fellows' data={this.state.fellows} setMobileNavHeaderBgColor={this.setMobileNavHeaderBgColor} />}
              <Fellows path='/fellows' data={this.state.fellows} setMobileNavHeaderBgColor={this.setMobileNavHeaderBgColor} />
              <Fellows path='/fellows/:year' data={this.state.fellows} setMobileNavHeaderBgColor={this.setMobileNavHeaderBgColor} />

              <AsyncRedirect path='/projects' to={this.state.workshops[0] && `/projects/${encodeURIComponent(this.state.projects[0].title)}`} />
              {(mobile({ tablet: true }) && <Description path='/description/projects' topNavData={constructProjectsTopNav(this.state.projects)} pageDescription={researchPageDescription} setMobileNavHeaderBgColor={this.setMobileNavHeaderBgColor} />) || <Project path='/projects' data={this.state.projects} setMobileNavHeaderBgColor={this.setMobileNavHeaderBgColor} />}
              <Project path='/projects/:project' data={this.state.projects} setMobileNavHeaderBgColor={this.setMobileNavHeaderBgColor} />

              <Happenings path='/happenings' data={this.state.happenings} images={this.state.homeGallery} setMobileNavHeaderBgColor={this.setMobileNavHeaderBgColor} />
              <Happenings path='/happenings/:view' data={this.state.happenings} images={this.state.homeGallery} setMobileNavHeaderBgColor={this.setMobileNavHeaderBgColor} />

              <About path='/about' data={this.state.about} redirects={redirects} setMobileNavHeaderBgColor={this.setMobileNavHeaderBgColor} />
              <Support path='/support' data={this.state.support} setMobileNavHeaderBgColor={this.setMobileNavHeaderBgColor} />
              <Participate path='/participate' data={this.state.participate} setMobileNavHeaderBgColor={this.setMobileNavHeaderBgColor} />
            </Router>
          </div>
        </div>
        {!mobile({ tablet: true }) &&
          <div class={cx('app__page-description', { 'app__page-description--hidden': !this.state.pageLinkHover, 'app__page-description--show': this.state.pageLinkHover })}>
            <PageDescription pageDescription={pageDescription} />
          </div>}
      </div>
    )
  }
}
