export default {
  parseIsoDatetime(dtstr) {
    let dt = dtstr.split(/[: T-]/).map(parseFloat);
    return new Date(dt[0], dt[1] - 1, dt[2], dt[3] || 0, dt[4] || 0, dt[5] || 0, 0);
  },

  sameYear(date1, date2) {
    return date1.getFullYear() === date2.getFullYear();
  },

  sameMonth(date1, date2) {
    return date1.getMonth() === date2.getMonth() &&
      date1.getFullYear() === date2.getFullYear();
  },

  sameDay(date1, date2) {
    return date1.getMonth() === date2.getMonth() &&
      date1.getDate() === date2.getDate() &&
      date1.getFullYear() === date2.getFullYear();
  },

  parseFellowYears(fellow) {
    const years = [];
    let startYear = this.parseIsoDatetime(fellow.startDate).getFullYear();
    let endYear = this.parseIsoDatetime(fellow.endDate).getFullYear();
    while (startYear < endYear) {
      years.push(`${startYear}\u2013${startYear + 1}`);
      startYear++;
    }

    return years;
  },

  sortHappeningsByDate(happenings) {
    if (happenings.length <= 1) {
      return happenings;
    }

    return happenings.sort((happening1, happening2) => {
      if (this.parseIsoDatetime(happening1.startDate) < this.parseIsoDatetime(happening2.startDate)) {
        return -1;
      }
      
      if (this.parseIsoDatetime(happening2.startDate) < this.parseIsoDatetime(happening1.startDate)) {
        return 1;
      }

      return 0;
    });
  },

  romanize(num) {
    if (isNaN(num))
      return NaN;
    let digits = String(+num).split(''),
      key = ['','C','CC','CCC','CD','D','DC','DCC','DCCC','CM',
        '','X','XX','XXX','XL','L','LX','LXX','LXXX','XC',
        '','I','II','III','IV','V','VI','VII','VIII','IX'],
      roman = '',
      i = 3;
    while (i--)
      roman = (key[+digits.pop() + (i * 10)] || '') + roman;
    return Array(+digits.join('') + 1).join('M') + roman;
  },

  arraysEqual(arr1, arr2) {
    return (arr1.length === arr2.length) && arr1.every((element, index) => element === arr2[index]);
  }
};